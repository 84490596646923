export const sales = [
  {
    id: 0,
    userId: 1,
    userName: 'System User',
    paymentMethodId: 2,
    paymentMethodName: 'Cash',
    dateTime: '3/24/2022, 11:10:07 PM',
    salesTotal: 9.72,
    productList: [
      {
        id: 1,
        salesQty: 1,
        isActive: true,
        productId: 6,
        rowTotal: 1.79,
        categoryId: 1,
        sku: 'PRD0007',
        name: 'Green Beans',
        price: 1.79,
        imageUrl: 'https://res.cloudinary.com/cmlmngr/image/upload/t_thmb/v1647263740/store_manager/categories/vegetables/green-beans-519439_1280_grxkks.jpg',
        stock: 6
      },
      {
        id: 2,
        salesQty: 1,
        isActive: false,
        productId: 6,
        rowTotal: 1.79,
        categoryId: 1,
        sku: 'PRD0007',
        name: 'Green Beans',
        price: 1.79,
        imageUrl: 'https://res.cloudinary.com/cmlmngr/image/upload/t_thmb/v1647263740/store_manager/categories/vegetables/green-beans-519439_1280_grxkks.jpg',
        stock: 6
      },
      {
        id: 3,
        salesQty: 1,
        isActive: true,
        productId: 1,
        rowTotal: 0.79,
        categoryId: 0,
        sku: 'PRD0002',
        name: 'Banana',
        price: 0.79,
        imageUrl: 'https://res.cloudinary.com/cmlmngr/image/upload/t_thmb/v1647263222/store_manager/categories/fruits/Cavendish_Banana_DS_wwky9l.jpg',
        stock: 5
      },
      {
        id: 4,
        salesQty: 1,
        isActive: false,
        productId: 1,
        rowTotal: 0.79,
        categoryId: 0,
        sku: 'PRD0002',
        name: 'Banana',
        price: 0.79,
        imageUrl: 'https://res.cloudinary.com/cmlmngr/image/upload/t_thmb/v1647263222/store_manager/categories/fruits/Cavendish_Banana_DS_wwky9l.jpg',
        stock: 5
      },
      {
        id: 5,
        salesQty: 1,
        isActive: true,
        productId: 0,
        rowTotal: 5.99,
        categoryId: 0,
        sku: 'PRD0001',
        name: 'Kiwi',
        price: 5.99,
        imageUrl: 'https://res.cloudinary.com/cmlmngr/image/upload/t_thmb/v1647263270/store_manager/categories/fruits/Kiwi_aka_dfa1br.jpg',
        stock: 3
      },
      {
        id: 6,
        salesQty: 1,
        isActive: true,
        productId: 9,
        rowTotal: 0.45,
        categoryId: 1,
        sku: 'PRD0010',
        name: 'Pepper Red',
        price: 0.45,
        imageUrl: 'https://res.cloudinary.com/cmlmngr/image/upload/t_thmb/v1647263605/store_manager/categories/vegetables/Red_bell_pepper_ocqt0p.jpg',
        stock: 23
      },
      {
        id: 7,
        salesQty: 1,
        isActive: true,
        productId: 7,
        rowTotal: 0.7,
        categoryId: 1,
        sku: 'PRD0008',
        name: 'Lettuce',
        price: 0.7,
        imageUrl: 'https://res.cloudinary.com/cmlmngr/image/upload/t_thmb/v1648330005/store_manager/categories/vegetables/300x300_khpvnf.jpg',
        stock: 4
      }
    ]
  },
  {
    id: 1,
    userId: 1,
    userName: 'System User',
    paymentMethodId: 1,
    paymentMethodName: 'Credit/Debit Card',
    dateTime: '3/24/2022, 11:10:31 PM',
    salesTotal: 39.69,
    productList: [
      {
        id: 8,
        salesQty: 1,
        isActive: true,
        productId: 18,
        rowTotal: 25,
        categoryId: 3,
        sku: 'PRD0019',
        name: 'Doll',
        price: 25,
        imageUrl: 'https://res.cloudinary.com/cmlmngr/image/upload/t_thmb/v1647264375/store_manager/categories/toys/doll-2575874_960_720_jti7me.jpg',
        stock: 3
      },
      {
        id: 9,
        salesQty: 1,
        isActive: false,
        productId: 19,
        rowTotal: 10.99,
        categoryId: 3,
        sku: 'PRD0020',
        name: 'Train',
        price: 10.99,
        imageUrl: 'https://res.cloudinary.com/cmlmngr/image/upload/t_thmb/v1647264346/store_manager/categories/toys/49941199837_3c79338c2e_b_ixuz5b.jpg',
        stock: 5
      },
      {
        id: 10,
        salesQty: 1,
        isActive: true,
        productId: 20,
        rowTotal: 11.2,
        categoryId: 3,
        sku: 'PRD0021',
        name: 'Bus',
        price: 11.2,
        imageUrl: 'https://res.cloudinary.com/cmlmngr/image/upload/t_thmb/v1647264129/store_manager/categories/toys/FCDOT_BUS_td7c54.png',
        stock: 2
      },
      {
        id: 11,
        salesQty: 1,
        isActive: true,
        productId: 4,
        rowTotal: 3.49,
        categoryId: 0,
        sku: 'PRD0005',
        name: 'Watermelon',
        price: 3.49,
        imageUrl: 'https://res.cloudinary.com/cmlmngr/image/upload/t_thmb/v1647264862/store_manager/categories/fruits/whole-and-slices-watermelon_dqy3rk.jpg',
        stock: 12
      }
    ]
  },
  {
    id: 2,
    userId: 1,
    userName: 'System User',
    paymentMethodId: 1,
    paymentMethodName: 'Credit/Debit Card',
    dateTime: '3/24/2022, 11:10:44 PM',
    salesTotal: 7.46,
    productList: [
      {
        id: 12,
        salesQty: 1,
        isActive: true,
        productId: 5,
        rowTotal: 2.29,
        categoryId: 0,
        sku: 'PRD0006',
        name: 'Orange',
        price: 2.29,
        imageUrl: 'https://res.cloudinary.com/cmlmngr/image/upload/t_thmb/v1647263205/store_manager/categories/fruits/2560px-Orange-Fruit-Pieces_avlvms.jpg',
        stock: 25
      },
      {
        id: 13,
        salesQty: 1,
        isActive: true,
        productId: 6,
        rowTotal: 1.79,
        categoryId: 1,
        sku: 'PRD0007',
        name: 'Green Beans',
        price: 1.79,
        imageUrl: 'https://res.cloudinary.com/cmlmngr/image/upload/t_thmb/v1647263740/store_manager/categories/vegetables/green-beans-519439_1280_grxkks.jpg',
        stock: 5
      },
      {
        id: 14,
        salesQty: 1,
        isActive: true,
        productId: 8,
        rowTotal: 2.59,
        categoryId: 1,
        sku: 'PRD0009',
        name: 'Brocoli',
        price: 2.59,
        imageUrl: 'https://res.cloudinary.com/cmlmngr/image/upload/t_thmb/v1647263651/store_manager/categories/vegetables/appetite-1238251_960_720_hfi4ct.jpg',
        stock: 1
      },
      {
        id: 15,
        salesQty: 1,
        isActive: true,
        productId: 1,
        rowTotal: 0.79,
        categoryId: 0,
        sku: 'PRD0002',
        name: 'Banana',
        price: 0.79,
        imageUrl: 'https://res.cloudinary.com/cmlmngr/image/upload/t_thmb/v1647263222/store_manager/categories/fruits/Cavendish_Banana_DS_wwky9l.jpg',
        stock: 4
      }
    ]
  },
  {
    id: 3,
    userId: 1,
    userName: 'System User',
    paymentMethodId: 2,
    paymentMethodName: 'Cash',
    dateTime: '3/26/2022, 9:13:10 PM',
    salesTotal: 28.98,
    productList: [
      {
        id: 1,
        salesQty: 1,
        isActive: true,
        productId: 3,
        rowTotal: 1.99,
        categoryId: 0,
        sku: 'PRD0004',
        name: 'Grapes',
        price: 1.99,
        imageUrl: 'https://res.cloudinary.com/cmlmngr/image/upload/t_thmb/v1647263247/store_manager/categories/fruits/50361658131_1c3d3556ff_b_d0gmtx.jpg',
        stock: 9
      },
      {
        id: 2,
        salesQty: 1,
        isActive: true,
        productId: 2,
        rowTotal: 1.29,
        categoryId: 0,
        sku: 'PRD0003',
        name: 'Apple',
        price: 1.29,
        imageUrl: 'https://res.cloudinary.com/cmlmngr/image/upload/t_thmb/v1647263195/store_manager/categories/fruits/2269px-Honeycrisp-Apple_lprha1.jpg',
        stock: 15
      },
      {
        id: 3,
        salesQty: 1,
        isActive: false,
        productId: 5,
        rowTotal: 2.29,
        categoryId: 0,
        sku: 'PRD0006',
        name: 'Orange',
        price: 2.29,
        imageUrl: 'https://res.cloudinary.com/cmlmngr/image/upload/t_thmb/v1647263205/store_manager/categories/fruits/2560px-Orange-Fruit-Pieces_avlvms.jpg',
        stock: 25
      },
      {
        id: 4,
        salesQty: 1,
        isActive: false,
        productId: 16,
        rowTotal: 11.99,
        categoryId: 2,
        sku: 'PRD0017',
        name: 'Diced Beef',
        price: 11.99,
        imageUrl: 'https://res.cloudinary.com/cmlmngr/image/upload/t_thmb/v1647263838/store_manager/categories/meats/05585_y7ubur.jpg',
        stock: 7
      },
      {
        id: 5,
        salesQty: 1,
        isActive: true,
        productId: 18,
        rowTotal: 25,
        categoryId: 3,
        sku: 'PRD0019',
        name: 'Doll',
        price: 25,
        imageUrl: 'https://res.cloudinary.com/cmlmngr/image/upload/t_thmb/v1647264375/store_manager/categories/toys/doll-2575874_960_720_jti7me.jpg',
        stock: 3
      },
      {
        id: 6,
        salesQty: 1,
        isActive: true,
        productId: 7,
        rowTotal: 0.7,
        categoryId: 1,
        sku: 'PRD0008',
        name: 'Lettuce',
        price: 0.7,
        imageUrl: 'https://res.cloudinary.com/cmlmngr/image/upload/t_thmb/v1648330005/store_manager/categories/vegetables/300x300_khpvnf.jpg',
        stock: 4
      }
    ]
  },
  {
    id: 4,
    userId: 1,
    userName: 'System User',
    paymentMethodId: 1,
    paymentMethodName: 'Credit/Debit Card',
    dateTime: '3/26/2022, 9:13:21 PM',
    salesTotal: 7.74,
    productList: [
      {
        id: 7,
        salesQty: 3,
        isActive: true,
        productId: 2,
        rowTotal: 3.87,
        categoryId: 0,
        sku: 'PRD0003',
        name: 'Apple',
        price: 1.29,
        imageUrl: 'https://res.cloudinary.com/cmlmngr/image/upload/t_thmb/v1647263195/store_manager/categories/fruits/2269px-Honeycrisp-Apple_lprha1.jpg',
        stock: 14
      },
      {
        id: 8,
        salesQty: 2,
        isActive: true,
        productId: 1,
        rowTotal: 1.58,
        categoryId: 0,
        sku: 'PRD0002',
        name: 'Banana',
        price: 0.79,
        imageUrl: 'https://res.cloudinary.com/cmlmngr/image/upload/t_thmb/v1647263222/store_manager/categories/fruits/Cavendish_Banana_DS_wwky9l.jpg',
        stock: 5
      },
      {
        id: 9,
        salesQty: 1,
        isActive: true,
        productId: 5,
        rowTotal: 2.29,
        categoryId: 0,
        sku: 'PRD0006',
        name: 'Orange',
        price: 2.29,
        imageUrl: 'https://res.cloudinary.com/cmlmngr/image/upload/t_thmb/v1647263205/store_manager/categories/fruits/2560px-Orange-Fruit-Pieces_avlvms.jpg',
        stock: 25
      }
    ]
  },
  {
    id: 5,
    userId: 1,
    userName: 'System User',
    paymentMethodId: 2,
    paymentMethodName: 'Cash',
    dateTime: '3/26/2022, 9:13:41 PM',
    salesTotal: 24.259999999999998,
    productList: [
      {
        id: 10,
        salesQty: 1,
        isActive: true,
        productId: 0,
        rowTotal: 5.99,
        categoryId: 0,
        sku: 'PRD0001',
        name: 'Kiwi',
        price: 5.99,
        imageUrl: 'https://res.cloudinary.com/cmlmngr/image/upload/t_thmb/v1647263270/store_manager/categories/fruits/Kiwi_aka_dfa1br.jpg',
        stock: 3
      },
      {
        id: 11,
        salesQty: 1,
        isActive: false,
        productId: 0,
        rowTotal: 5.99,
        categoryId: 0,
        sku: 'PRD0001',
        name: 'Kiwi',
        price: 5.99,
        imageUrl: 'https://res.cloudinary.com/cmlmngr/image/upload/t_thmb/v1647263270/store_manager/categories/fruits/Kiwi_aka_dfa1br.jpg',
        stock: 3
      },
      {
        id: 12,
        salesQty: 1,
        isActive: true,
        productId: 2,
        rowTotal: 1.29,
        categoryId: 0,
        sku: 'PRD0003',
        name: 'Apple',
        price: 1.29,
        imageUrl: 'https://res.cloudinary.com/cmlmngr/image/upload/t_thmb/v1647263195/store_manager/categories/fruits/2269px-Honeycrisp-Apple_lprha1.jpg',
        stock: 11
      },
      {
        id: 13,
        salesQty: 1,
        isActive: true,
        productId: 15,
        rowTotal: 7.99,
        categoryId: 2,
        sku: 'PRD0016',
        name: 'Minced Beef',
        price: 7.99,
        imageUrl: 'https://res.cloudinary.com/cmlmngr/image/upload/t_thmb/v1647263898/store_manager/categories/meats/extra-lean-minced-beef_yihynx.jpg',
        stock: 11
      },
      {
        id: 14,
        salesQty: 1,
        isActive: true,
        productId: 13,
        rowTotal: 8.99,
        categoryId: 2,
        sku: 'PRD0014',
        name: 'Lamb Leg',
        price: 8.99,
        imageUrl: 'https://res.cloudinary.com/cmlmngr/image/upload/t_thmb/v1647263975/store_manager/categories/meats/lamb-leg-bone_530x_uxqnnz.jpg',
        stock: 6
      }
    ]
  },
  {
    id: 6,
    userId: 1,
    userName: 'System User',
    paymentMethodId: 1,
    paymentMethodName: 'Credit/Debit Card',
    dateTime: '3/26/2022, 9:14:04 PM',
    salesTotal: 25.150000000000002,
    productList: [
      {
        id: 15,
        salesQty: 2,
        isActive: true,
        productId: 13,
        rowTotal: 17.98,
        categoryId: 2,
        sku: 'PRD0014',
        name: 'Lamb Leg',
        price: 8.99,
        imageUrl: 'https://res.cloudinary.com/cmlmngr/image/upload/t_thmb/v1647263975/store_manager/categories/meats/lamb-leg-bone_530x_uxqnnz.jpg',
        stock: 5
      },
      {
        id: 16,
        salesQty: 3,
        isActive: true,
        productId: 6,
        rowTotal: 5.37,
        categoryId: 1,
        sku: 'PRD0007',
        name: 'Green Beans',
        price: 1.79,
        imageUrl: 'https://res.cloudinary.com/cmlmngr/image/upload/t_thmb/v1647263740/store_manager/categories/vegetables/green-beans-519439_1280_grxkks.jpg',
        stock: 6
      },
      {
        id: 17,
        salesQty: 4,
        isActive: true,
        productId: 9,
        rowTotal: 1.8,
        categoryId: 1,
        sku: 'PRD0010',
        name: 'Pepper Red',
        price: 0.45,
        imageUrl: 'https://res.cloudinary.com/cmlmngr/image/upload/t_thmb/v1647263605/store_manager/categories/vegetables/Red_bell_pepper_ocqt0p.jpg',
        stock: 23
      }
    ]
  }
]